import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./pages/Header";

import "./index.css";

const MainLayout: React.FC = () => {

  return (
    <div className="relative min-h-screen text-white">
      <Header />
      <main className="pt-14">
        <Outlet />
      </main>
    </div >
  )
};

export default MainLayout;
