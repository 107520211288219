import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { decodeJwt } from 'jose';

interface AuthorizedFile {
  authorizedFileTypes: string[];
  message: string;
  name: string;
  type: string;
  required?: boolean;
}

interface JwtPayload {
  token: string;
  interlocutorId: string;
  files: AuthorizedFile[];
  replyCode?: string;
}

const UploadPage: React.FC = () => {
  const [jwtToken, setJwtToken] = useState<string | null>(null);

  const [interlocutorId, setInterlocutorId] = useState<string | null>(null);
  const [fileList, setFileList] = useState<AuthorizedFile[]>([]);
  const [replyCode, setReplyCode] = useState<string | null>(null);

  const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);
  const [selectedFiles, setSelectedFiles] = useState<{ name: string; file: File | null }[]>([]);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');

    if (tokenFromUrl) {
      try {
        const decoded = decodeJwt(tokenFromUrl) as JwtPayload;

        console.log("JWT décodé : ", decoded);

        setJwtToken(tokenFromUrl);
        setInterlocutorId(decoded.interlocutorId);
        setFileList(decoded.files);

        if (decoded.replyCode) {
          setReplyCode(decoded.replyCode);
        }

      } catch (error) {
        console.error("Erreur lors du décodage du JWT : ", error);
        alert("JWT invalide.");
      }
    } else {
      alert("Token JWT manquant dans l'URL.");
    }
  }, []);

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const param = fileList[currentFileIndex];

      if (param && param.authorizedFileTypes.includes(file.type)) {
        setSelectedFiles((prevFiles) => [
          ...prevFiles.filter((item) => item.name !== param.name),
          { name: param.name, file }
        ]);
        setIsFileSelected(true);
        resetFileInput();
      } else {
        alert(`Le fichier doit être de type: ${param?.authorizedFileTypes.join(', ')}`);
      }
    }
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      const param = fileList[currentFileIndex];

      if (!param) return;

      if (param.authorizedFileTypes.includes(file.type)) {

        setSelectedFiles((prevFiles) => [
          ...prevFiles.filter((item) => item.name !== param.name),
          { name: param.name, file }
        ]);

        setIsFileSelected(true);
      } else {
        alert(`Le fichier doit être de type: ${param?.authorizedFileTypes.join(', ')}`);
      }
    }
  };

  const nextFile = () => {
    if (isFileSelected || !fileList[currentFileIndex].required) {
      setCurrentFileIndex(currentFileIndex + 1);
      setIsFileSelected(false);
    }
  };

  const upload = async () => {
    if (!jwtToken || !interlocutorId) {
      alert("Token JWT ou Interlocutor ID introuvable.");
      return;
    }

    try {
      const url = `https://api.staging.clustaar.io/interlocutors/${interlocutorId}/documents/upload_presigned_urls?token=${jwtToken}`;

      for (const { name, file } of selectedFiles) {
        if (file) {
          const param = fileList.find((f) => f.name === name);

          if (param) {
            console.log("Envoi du fichier:", file);

            const response = await axios.post(url, {
              fileName: param.name,
              contentType: file.type,
            });

            const { url: presignedUrl, data, fileURL, clustaarURL } = response.data.data;

            const formData = new FormData();

            formData.append('acl', data.acl);
            formData.append('Content-Type', data['Content-Type']);
            formData.append('key', data.key);
            formData.append('AWSAccessKeyId', data.AWSAccessKeyId);
            formData.append('policy', data.policy);
            formData.append('signature', data.signature);
            formData.append('file', file);


            await axios.post(presignedUrl, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            console.log(`Fichier ${file.name} téléchargé avec succès sur ${fileURL}.`);
            console.log(`Clustaar URL: ${clustaarURL}`);
          }
        }
      }

      setIsUploadComplete(true);

    } catch (error) {
      console.log("Erreur lors du téléversement : ", error);

      alert("Une erreur s'est produite lors de l'upload. Veuillez vérifier votre fichier et réessayer.");
      setIsUploadComplete(true);
    }
  };

  const currentFile = fileList[currentFileIndex];

  const canUploadFiles = () => {
    const nbSelectedFiles = selectedFiles.filter(f => !!f.file).length
    const nbRequiredFiles = fileList.filter(f => f.required).length
    const nbAskedFiles = fileList.length


    if (nbSelectedFiles <= nbAskedFiles && nbSelectedFiles >= nbRequiredFiles) {
      return true
    }
    return false
  }

  return (
    <div className="min-h-screen text-white p-6">
      <div className="flex items-center justify-center h-full">
        <div className="w-full max-w-2xl">
          <h1 className="text-3xl font-bold mb-6">Spellz Upload</h1>

          <form className="border-2 border-indigo-500 rounded px-8 pt-6 pb-6 mb-4">
            {isUploadComplete && replyCode ? (
              <div className="text-center">
                <p className="text-lg font-bold text-green-500">{replyCode}</p>
              </div>
            ) : currentFile ? (
              <>
                <div className="flex pb-6 items-center">
                  <div className="flex flex-col justify-between w-full">
                    <label className="block text-gray-400 text-sm font-bold mb-2">
                      {currentFile.name} ({currentFile.authorizedFileTypes.map(type => type.split('/')[1]).join(', ').toUpperCase()})
                      {currentFile.required ? <span className="text-red-500">  * Required</span> : <span className="text-gray-600">  Optional </span>}
                    </label>
                    <div
                      className="flex border h-36 px-6 text-center items-center place-content-center rounded border-dashed border-rose-700 mb-4"
                      onClick={handleFileSelect}
                      onDrop={handleDrop}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <span>
                        {selectedFiles.find((file) => file.name === currentFile.name)?.file?.name ||
                          "Déposez une image ou appuyez ici pour en upload une !"}
                      </span>
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept={currentFile.authorizedFileTypes.join(',')}
                  onChange={handleFileChange}
                />
                <div className="flex flex-col justify-center ">
                  {currentFileIndex < fileList.length - 1 ? (
                    <button
                      className={`mb-2 border-2 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isFileSelected || !currentFile.required
                        ? "border-teal-500 text-white hover:text-black hover:bg-teal-500"
                        : "border-gray-500 text-gray-500 cursor-not-allowed"
                        }`}
                      type="button"
                      onClick={nextFile}
                      disabled={!isFileSelected && currentFile.required}
                    >
                      Suivant
                    </button>
                  ) : (
                    <button
                      onClick={upload}
                      className={`border-2 border-teal-500 text-white hover:text-black hover:bg-teal-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${canUploadFiles() ? "" : "border-gray-500 text-gray-500 cursor-not-allowed"
                        }`}
                      type="button"
                      disabled={!canUploadFiles()}
                    >
                      Envoyer la sélection
                    </button>
                  )}
                </div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadPage;
