import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header className="px-4 h-12 border-indigo-500/60 text-white flex items-center justify-between shadow-xl shadow-indigo-500/60">
      <Link to="/" className="flex items-center">
        <img src="/spellz.svg" alt="Spellz Icon" className="h-6 w-6" />
        <span className="ml-2">Home</span>
      </Link>
    </header>
  );
};

export default Header;
